<template>
  <ageing-template
    :wrinkles="wrinkles"
    :wrinkles-ai-selected="wrinklesAiSelected"
    :drying-skin="dryingSkin"
    :drying-skin-visible="isDryingQuestionsVisible"
    :dry-open-pores="dryOpenPores"
    :dry-open-pores-visible="isDryingQuestionsVisible"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import AgeingTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-appearance/ageing/AgeingTemplate';

import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';
import { makeStep, mapAiSelectedFields, resetStoreValues } from '@/modules/questionnaire/mixins';

import { no } from '@/modules/questionnaire/api/helpers';

const { field, requiredField, requiredArrayField } = fieldBuilder;

const FIELDS_TO_RESET = ['skinAppearanceDiscomfort'];

const AI_SELECTED_FIELD_NAMES = ['wrinkles'];

const FIELDS = [
  field('age'),
  ...AI_SELECTED_FIELD_NAMES.map(requiredField),
  requiredArrayField('dryingSkin'),
  requiredArrayField('dryOpenPores'),
  ...FIELDS_TO_RESET.map(field)
];

export default {
  name: 'Ageing',
  components: { AgeingTemplate },
  mixins: [makeStep(FIELDS), mapAiSelectedFields(AI_SELECTED_FIELD_NAMES), resetStoreValues],
  computed: {
    isDryingQuestionsVisible() {
      return this.age >= 25;
    }
  },
  watch: {
    wrinkles(newValue, oldValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(FIELDS_TO_RESET);
      }

      if (!this.isDryingQuestionsVisible) {
        this.showNextStep();
        return;
      }

      if (!oldValue && this.dryingSkin && this.dryOpenPores) {
        this.showNextStep();
        return;
      }

      this.scrollTo('#drying-skin');
    },
    dryingSkin(newValue, oldValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(FIELDS_TO_RESET);
      }

      if (!oldValue && this.wrinkles && this.dryOpenPores) {
        this.showNextStep();
        return;
      }

      this.scrollTo('#dry-open-pores');
    },
    dryOpenPores(newValue, oldValue) {
      if (!oldValue && this.wrinkles && this.dryingSkin) {
        this.showNextStep();
      }
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'dryingSkin' || fieldName === 'dryOpenPores') {
        return this.isDryingQuestionsVisible;
      }

      return true;
    }
  }
};
</script>
